<template>
  <div class="o-order-info">
    <span class="o-order-info__icon o-order-info__icon-user" />
    <SfHeading
      :title="$t('Personal Information')"
      :level="2"
      class="o-order-info__title o-order-info__title--personal-details"
    />
    <div class="o-order-info__content">
      <div class="o-order-info-content__row short-description">
        <span class="short-description__label">{{ $t("Phone") }}</span>
        <span class="short-description__value">{{ phone }}</span>
      </div>
      <div v-if="name" class="o-order-info-content__row short-description">
        <span class="short-description__label">{{ $t("First name") }}</span>
        <span class="short-description__value">{{ name }}</span>
      </div>
      <div v-if="email" class="o-order-info-content__row short-description">
        <span class="short-description__label">{{ $t("E-mail") }}</span>
        <span class="short-description__value">{{ email }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SfHeading
} from '@storefront-ui/vue';

export default {
  name: 'OPersonalDetailsInfo',
  data () {
    return {}
  },
  props: {
    personalDetails: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    SfHeading
  },
  computed: {
    phone () {
      return this.personalDetails.phone
    },
    name () {
      return this.personalDetails.firstname
    },
    email () {
      return this.personalDetails.email
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-thank-you-page";

.title {
  --heading-padding: var(--spacer-base) 0;

  @include for-desktop {
    --heading-title-font-size: var(--h3-font-size);
    --heading-padding: var(--spacer-2xl) 0 var(--spacer-base) 0;
  }
}
.user-info {
  &__row {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
  &__label {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--dark-gray);
    flex: 0 0 90px;
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }
  &__value {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--black);
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }
}
</style>
