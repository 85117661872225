<template>
  <div class="o-order-info">
    <span class="o-order-info__icon o-order-info__icon-payment" />
    <SfHeading
      :title="$t('Payment method')"
      :level="2"
      :subtitle="subtitle"
      class="o-order-info__title"
    />
  </div>
</template>

<script>
import {
  SfHeading
} from '@storefront-ui/vue'

export default {
  name: 'OPaymentInfo',
  components: {
    SfHeading
  },
  data () {
    return {}
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => []
    },
    paymentDetails: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    subtitle () {
      const code = this.paymentMethods.find(method => method.code === this.paymentDetails.paymentMethod)
      return this.$t(code.title)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/components/organisms/o-thank-you-page";
</style>
