<template>
  <div class="o-order-info">
    <span class="o-order-info__icon o-order-info__icon-clock" />
    <SfHeading
      :title="$t('Time of delivery')"
      :level="2"
      class="o-order-info__title"
    />
    <div class="o-order-info__content">
      <div
        class="o-order-info-content__row short-description"
      >
        <div class="short-description__value">
          <span>{{ currentTimeSlotString }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfHeading
} from '@storefront-ui/vue'

export default {
  name: 'OTimeSlotsInfo',
  components: {
    SfHeading
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    shippingTimeSlots: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      monthTranslates: [
        '{day} January {year}',
        '{day} February {year}',
        '{day} March {year}',
        '{day} April {year}',
        '{day} May {year}',
        '{day} June {year}',
        '{day} Jule {year}',
        '{day} August {year}',
        '{day} September {year}',
        '{day} October {year}',
        '{day} November {year}',
        '{day} December {year}'
      ]
    }
  },
  computed: {
    timeSlotsDates () {
      const uniqueDates = [...new Set(this.shippingTimeSlots.map(slot => slot.date))]
      const currentDate = new Date()
      const result = []

      uniqueDates.forEach(date => {
        result.push(this.formatDateToObject(date, currentDate))
      })

      return result
    },
    currentTimeSlot () {
      return this.order.addressInformation.shippingAddress.extension_attributes.timeslot
    },
    currentTimeSlotString () {
      const dateObject = this.timeSlotsDates.find(date => date.value === this.currentTimeSlot.date)
      return (dateObject?.label || '') + (this.currentTimeSlot?.value ? ', ' + this.currentTimeSlot.value : '')
    }
  },
  methods: {
    formatDateToObject (date, currentDate) {
      const dateParts = date.split('-')
      const result = {
        value: date,
        label: ''
      }

      if (dateParts.length !== 3) {
        return result
      }

      const dateObject = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

      if (
        currentDate.getFullYear() === dateObject.getFullYear() &&
        currentDate.getMonth() === dateObject.getMonth()
      ) {
        const diffDates = dateObject.getDate() - currentDate.getDate()

        if (diffDates === 0) {
          result.label = this.$t('Today')
        } else if (diffDates === 1) {
          result.label = this.$t('Tomorrow')
        }
      }

      if (!result.label) {
        result.label = this.$t(this.monthTranslates[dateObject.getMonth()], {
          day: dateObject.getDate(),
          year: currentDate.getFullYear() !== dateObject.getFullYear() ? dateObject.getFullYear() : ''
        })
      }

      return result
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-thank-you-page";
</style>
