<template>
  <div class="o-order-info">
    <span class="o-order-info__icon o-order-info__icon-update" />
    <SfHeading
      :title="$t('Replacement and packaging of goods')"
      :level="2"
      class="o-order-info__title"
    />
    <div class="o-order-info__content">
      <div v-if="replacementTitle" class="o-order-info-content__row short-description">
        <span class="short-description__label">{{ $t("Replacement") }}</span>
        <span class="short-description__value">{{ $t(replacementTitle) }}</span>
      </div>
      <div v-if="packagingTitle" class="o-order-info-content__row short-description">
        <span class="short-description__label">{{ $t("Package") }}</span>
        <span class="short-description__value">{{ $t(packagingTitle) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfHeading
} from '@storefront-ui/vue'
import config from 'config'

export default {
  name: 'OPackageInfo',
  components: {
    SfHeading
  },
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    shipping: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    replacementTitle () {
      const replacementOptions = config.checkout.replaceProductActions
      const replacement = replacementOptions?.find(item => item.code === this.order?.addressInformation?.shippingAddress?.extension_attributes?.replacement)
      return replacement?.title
    },
    packagingTitle () {
      const packageOptions = config.checkout.packagingProductActions[this.shipping.shippingMethodType]
      const packaging = packageOptions?.find(item => item.code === this.order?.addressInformation?.shippingAddress?.extension_attributes?.packaging)
      return packaging?.title
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/components/organisms/o-thank-you-page";
</style>
