<template>
  <div class="o-order-info">
    <span class="o-order-info__icon o-order-info__icon-location" />
    <SfHeading
      :title="$t('Shipping method')"
      :subtitle="subtitle"
      :level="2"
      class="o-order-info__title"
    />
    <div
      v-if="isOtherRecipient"
      class="o-order-info__content"
    >
      <div class="o-order-info-content__row short-description">
        <div class="short-description__label">
          {{ $t('Recipient') }}
        </div>
        <div class="short-description__value">
          <span>{{ name }}</span>
          <span>{{ phone }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfHeading
} from '@storefront-ui/vue'
import { newPostMethods } from '$modules/shipping/config';

export default {
  name: 'OShippingInfo',
  components: {
    SfHeading
  },
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    shipping: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    subtitle () {
      return this.$t(this.order.addressInformation.shipping_method_code.toLowerCase()) +
        ': ' +
        this.order.addressInformation.shippingAddress.city +
        ', ' +
        this.order.addressInformation.shippingAddress.street.join(', ')
    },
    phone () {
      return this.order.addressInformation.shippingAddress.telephone
    },
    name () {
      return this.order.addressInformation.shippingAddress.firstname
    },
    isOtherRecipient () {
      if (newPostMethods.includes(this.order.addressInformation.shipping_method_code.toUpperCase())) {
        return true
      }

      return this.shipping.isOtherRecipient
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-thank-you-page";
</style>
